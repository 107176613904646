<template>
  <div>
    <div class="money-header">
      <div class="data">
        <p class="m_title" @click="showTips">
          我的零钱(元)
          <img src="@image/me/money_tips.png" />
        </p>
        <div class="price_info">
          <p class="price">{{ member_info }}</p>
          <svg
            @click="goApply"
            width="106"
            height="42"
            viewBox="0 0 106 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.28 34.82L47.65 32.06C48.55 32.16 49.32 32.21 49.96 32.21C50.64 32.21 50.98 31.88 50.98 31.22V24.74C50.3 25 49.26 25.37 47.86 25.85L47.17 22.97C48.35 22.69 49.62 22.31 50.98 21.83V15.65H47.59V12.92H50.98V7.49H53.86V12.92H56.65V15.65H53.86V20.63C54.58 20.29 55.34 19.9 56.14 19.46V22.37L53.86 23.51V31.94C53.86 33.86 52.89 34.82 50.95 34.82H48.28ZM58.69 8.33H72.19V19.16H58.69V8.33ZM69.4 16.85V14.81H61.45V16.85H69.4ZM61.45 12.65H69.4V10.64H61.45V12.65ZM56.74 20.72H73.99V23.27H66.79V26.03H72.7V28.55H66.79V31.73C68.09 31.87 69.39 31.94 70.69 31.94C72.31 31.94 73.69 31.88 74.83 31.76L74.05 34.52C73.21 34.52 72.15 34.5 70.87 34.46C66.85 34.42 64.12 34.05 62.68 33.35C61.62 32.81 60.67 31.85 59.83 30.47C59.15 32.11 58.25 33.64 57.13 35.06L55 33.23C57.16 30.53 58.36 27.54 58.6 24.26L61.12 24.56C61.06 25.56 60.92 26.59 60.7 27.65C61.62 29.43 62.65 30.55 63.79 31.01L64.06 31.1V23.27H56.74V20.72ZM102.61 8.42V25.31H99.73V11.15H91.57V25.31H88.75V8.42H102.61ZM98.59 34.61C96.73 34.61 95.8 33.64 95.8 31.7V26.96C95.42 28.08 94.95 29.09 94.39 29.99C92.93 32.25 90.51 34 87.13 35.24L85.57 32.84C88.85 31.6 91.08 30.03 92.26 28.13C93.42 26.11 94.06 23.47 94.18 20.21V13.04H96.91V20.21C96.85 22.01 96.65 23.67 96.31 25.19H98.65V31.1C98.65 31.7 98.91 32 99.43 32H101.2C101.56 32 101.83 31.85 102.01 31.55C102.21 31.17 102.35 30.14 102.43 28.46L105.07 29.33C104.87 31.87 104.51 33.4 103.99 33.92C103.47 34.38 102.71 34.61 101.71 34.61H98.59ZM77.8 18.26H81.01V11.9H77.41V9.17H87.43V11.9H83.89V18.26H87.01V21.02H83.89V26.96C85.13 26.54 86.33 26.09 87.49 25.61V28.34C84.51 29.54 81.31 30.56 77.89 31.4L77.2 28.67C78.58 28.41 79.85 28.13 81.01 27.83V21.02H77.8V18.26Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.6123 8.88438H35.7938L32.9984 4.04266C31.8939 2.12949 29.4475 1.47399 27.5343 2.57856L16.6123 8.88438Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4 10.1001C1.79086 10.1001 0 11.891 0 14.1001V36.0018C0 38.2109 1.79087 40.0018 4 40.0018H35.8689C38.0781 40.0018 39.8689 38.2109 39.8689 36.0018V28.3734H29.9023C27.6087 28.3734 25.7493 26.514 25.7493 24.2204C25.7493 21.9268 27.6087 20.0674 29.9023 20.0674H39.8689V14.1001C39.8689 11.891 38.0781 10.1001 35.8689 10.1001H4Z"
              fill="white"
            />
            <circle cx="29.9014" cy="24.2208" r="1.66121" fill="white" />
          </svg>
        </div>
      </div>
      <div class="reload">
        <p class="r-title">累计提现(元)</p>
        <p class="r-price">{{ withdrawals }}</p>
      </div>
    </div>

    <div class="money-ul" v-if="false">
      <h2>提现记录</h2>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <ul>
          <li class="money-li" v-for="item in moneyList" :key="item.pdc_sn">
            <div class="left">
              <div class="info">
                <b v-if="item.pdc_payment_state == 0">
                  申请成功
                </b>
                <b v-if="item.pdc_payment_state == 1">
                  提现成功
                </b>
                <b v-if="item.pdc_payment_state == 2">
                  提现失败
                </b>
                <span> ￥{{ item.pdc_amount }} </span>
              </div>
              <span class="time">{{ item.pdc_addtime | time }}</span>
            </div>
            <div class="right">
              <svg
                v-if="item.pdc_payment_state == 0"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="20"
                  cy="20"
                  r="18"
                  stroke="#118FE7"
                  stroke-width="3"
                />
                <path
                  d="M18.9948 13.0052L18.9952 21.4928L30.4928 21.4928"
                  stroke="#118FE7"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                v-if="item.pdc_payment_state == 1"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="20"
                  cy="20"
                  r="18"
                  stroke="#21CA71"
                  stroke-width="3"
                />
                <path
                  d="M11 21.3571L16.6604 26L28.5 14"
                  stroke="#21CA71"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <svg
                v-if="item.pdc_payment_state == 2"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="20"
                  cy="20"
                  r="18"
                  stroke="#FF4848"
                  stroke-width="3"
                />
                <path
                  d="M14.5693 26.2832L26.409 14.2832"
                  stroke="#FF4848"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.8828 14.2832L25.8828 26.1228"
                  stroke="#FF4848"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </li>
        </ul>
      </van-list>
    </div>

    <ul class="money-tool">
      <li @click="$router.push({ name: 'Apply' })">
        <p>立即提现</p>
        <span>提现到微信零钱</span>
        <svg
          width="15"
          height="26"
          viewBox="0 0 15 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </li>

      <li @click="$router.push({ name: 'MoneyReload' })">
        <p>提现记录</p>
        <span></span>
        <svg
          width="15"
          height="26"
          viewBox="0 0 15 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </li>
      <li @click="$router.push({ name: 'Income' })">
        <p>收益明细</p>
        <span></span>
        <svg
          width="15"
          height="26"
          viewBox="0 0 15 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L12.2929 12.2929C12.6834 12.6834 12.6834 13.3166 12.2929 13.7071L2 24"
            stroke="#666666"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </li>
    </ul>
  </div>
</template>

<script>
import { post } from "@get/http";
import { List, Toast } from "vant";
export default {
  components: {
    vanList: List,
  },
  data() {
    return {
      member_info: {},
      moneyList: [],
      withdrawals: 0,
      loading: false,
      finished: false,
      page: 1,
    };
  },

  async created() {
    let { member_info } = await post("/v2.Member/index");
    this.member_info = member_info.available_predeposit;
    this.withdrawals = member_info.withdrawals;
  },
  methods: {
    showTips() {
      Toast("零钱包含佣金和充值款哦！");
    },
    goApply() {
      this.$router.push({
        name: "Apply",
      });
    },
    async onLoad() {
      let { data } = await post("/v2.Memberfund/pdcashlist", {
        data: {
          page: this.page,
          per_page: 20,
          // time_type: 0,
        },
      });
      this.moneyList.push(...data);
      this.loading = false;
      this.page++;
      // 加载状态结束
      if (data && data.length != 20) {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
// .money-bg {
//   background: #ffb0b0;
//   filter: blur(rem(10));
// }
.money-header {
  background: url("~@image/me/money_bg2.png") no-repeat;
  background-size: rem(690) rem(368);
  width: rem(690);
  height: rem(368);
  margin: rem(24) auto 0;
  box-sizing: border-box;
  padding: rem(32);
  .data {
    margin-bottom: rem(75);
    .m_title {
      margin-bottom: rem(16);
      color: #ffffff;
      font-size: rem(24);

      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: rem(23);
        height: rem(23);
        margin-left: rem(10);
      }
    }
    .price_info {
      display: flex;
      align-items: center;
      .price {
        flex: 1;
        color: #ffffff;
        font-weight: bold;
        font-size: rem(56);
      }
      > svg {
        width: rem(106);
        height: rem(42);
      }
    }
  }
  .reload {
    color: #ffffff;
    .r-title {
      font-size: rem(24);
    }
    .r-price {
      font-weight: bold;
      font-size: rem(32);
    }
  }
  margin-bottom: rem(30);
}

.money-ul {
  width: rem(690);
  margin: 0 auto;
  h2 {
    font-weight: bold;
    font-size: rem(28);
    color: #222222;
    padding-left: rem(24);
    margin: rem(24) 0;
  }
  .money-li {
    box-sizing: border-box;
    padding: rem(24);
    display: flex;
    background: #fff;
    border-radius: rem(16);
    margin-bottom: rem(16);
    align-items: center;

    .left {
      flex: 1;
      .info {
        flex: 1;
        > b {
          font-size: rem(28);
          color: #222222;
        }
        > span {
          color: #ff4848;
          font-weight: bold;
          font-size: rem(28);
          margin-left: rem(6);
          display: inline-block;
        }
      }
      .time {
        color: #666666;
        font-size: rem(24);
        margin-top: rem(8);
      }
    }
    .right {
      svg {
        width: rem(36);
        height: rem(36);
      }
    }
  }
}

.money-tool {
  background: #ffffff;
  border-radius: rem(16) rem(16) 0px 0px;
  width: rem(690);
  margin: 0 auto;
  li {
    font-size: rem(28);
    display: flex;
    align-items: center;
    height: rem(104);
    box-sizing: border-box;
    padding: 0 rem(30);

    p {
      color: #222222;
    }
    span {
      color: #666666;
      flex: 1;
      text-align: right;
    }
    svg {
      width: rem(11);
      height: rem(22);
      margin-left: rem(10);
    }
  }
}
</style>
